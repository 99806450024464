import React from "react"
import { rhythm } from "../utils/typography"

export default function Image({ src, title, subtitle, date }) {
  return (
    <div style={{ paddingBottom: "35px" }}>
      <img style={{ minWidth: "354px" }} src={src} alt="" />
      <div>
        <p style={{ float: "right" }}>{date}</p>
        <p>{title}</p>
        <p style={{ lineHeight: rhythm(0) }}>{subtitle}</p>
      </div>
    </div>
  )
}
