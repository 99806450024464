import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

const indexFilters = (setFilter, currentFilter) => {
  return ["All", "Tops", "Bottoms", "Dresses", "Misc"].map(linkName => (
    <h3
      style={{
        fontStyle: "normal",
        marginBottom: "0px",
        cursor: "pointer",
        textDecoration: linkName === currentFilter ? "underline" : "initial",
      }}
      onClick={() => setFilter(linkName)}
    >
      {linkName}
    </h3>
  ))
}

const aboutLinks = () => {
  return [
    <h3
      style={{
        fontStyle: "normal",
        marginBottom: "0px",
        textDecoration: "underline",
      }}
    >
      About
    </h3>,
    <Link to="https://memos.earth">memos.earth</Link>,
    <Link to="https://memos.earth">collections.earth</Link>,
  ]
}

export default function Nav({ about, setFilter, currentFilter }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div
      style={{
        position: "fixed",
        background: "white",
        opacity: "0.90",
        width: "100%",
        maxWidth: "622px",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "622px",
          borderStyle: "solid",
          borderWidth: "2px",
          padding: "24px",
        }}
      >
        <Link to={`/`}>
          <h2
            style={{
              display: "inline-block",
              fontStyle: "normal",
              marginBottom: "0px",
            }}
          >
            {data.site.siteMetadata.title}
          </h2>
        </Link>
        <Link
          to={about ? "/" : "/about/"}
          style={{ float: "right", textDecoration: "initial" }}
        >
          <h3
            style={{
              fontStyle: "normal",
              marginBottom: "0px",
              textDecoration: "initial",
            }}
          >
            {about ? "Home" : "About"}
          </h3>
        </Link>
      </div>
      <div
        style={{
          width: "100%",
          maxWidth: "622px",
          borderStyle: "solid",
          borderWidth: "2px",
          borderTopStyle: "none",
          padding: "24px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {about ? aboutLinks() : indexFilters(setFilter, currentFilter)}
      </div>
    </div>
  )
}
