import React, { useState } from "react"

const taglines = ["Whatever you do, do it carefully", "Measure twice, cut once", "The delicate toil of the needle"]
const randomTagline = () =>
  taglines[Math.floor(Math.random() * taglines.length)]

const cursor = "|"
const nocursor = "&nbsp;"
const numBlinksAtEnd = 8

export default function Tagline() {
  const [tagline] = useState(randomTagline())
  const [innerHtml, setInnerHtml] = useState(`<span>${cursor}</span>`)
  const [counter, setCounter] = useState(0)

  if (counter < tagline.length + 1) {
    setTimeout(() => {
      setCounter(counter + 1)
      setInnerHtml(
        `<span>${tagline.substring(0, counter)}${
          counter >= tagline.length ? nocursor : cursor
        }</span>`
      )
    }, 60 + Math.floor(Math.random() * 10))
  } else if (counter < tagline.length + 1 + numBlinksAtEnd) {
    setTimeout(() => {
      setCounter(counter + 1)
      setInnerHtml(`<span>${tagline}${counter % 2 ? nocursor : cursor}</span>`)
    }, 500)
  } else if (counter === tagline.length + 1 + numBlinksAtEnd) {
    setTimeout(() => {
      setCounter(counter + 1)
      setInnerHtml(`<span>${tagline}${nocursor}</span>`)
    }, 500)
  }

  return <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
}
