import Typography from "typography"
import kirkhamTheme from "typography-theme-kirkham"

kirkhamTheme.headerFontFamily = ["Andale Mono", "sans-serif"]
kirkhamTheme.bodyFontFamily = ["Andale Mono", "sans-serif"]
kirkhamTheme.headerWeight = "400"
kirkhamTheme.bodyWeight = "400"

const typography = new Typography(kirkhamTheme)

export default typography
export const rhythm = typography.rhythm
