import React, { useState } from "react"
import Tagline from "./tagline"
import Nav from "./nav"

import { rhythm } from "../utils/typography"

export default function Layout({ about, children, onFilterChange }) {
  const [currentFilter, setFilter] = useState("All")
  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "1340px",
        padding: rhythm(2),
        paddingTop: rhythm(1.5),
      }}
    >
      <div
        style={{
          paddingBottom: "145px",
        }}
      >
        <Nav
          about={about}
          currentFilter={currentFilter}
          setFilter={newFilter => {
            setFilter(newFilter)
            onFilterChange(newFilter)
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "20px",
          }}
        >
          <Tagline />
        </div>
      </div>
      {children}
    </div>
  )
}
